<template>
  <div class="container" :style="questionnaire.backgroundImageUrl &&
    questionnaire.backgroundImageUrl != ''
    ? 'background: url(' +
    questionnaire.backgroundImageUrl +
    '); background-size: contain;'
    : ''
    ">
    <div v-if="fristPoster">
      <img :src="'./image/njxg/3.jpg'" usemap="#image-map" style="max-width: 100%; height: 100vh;"
        @click="hidefristPost()">
    </div>
    <div v-else>
      <van-loading class="loading" color="#9d1d22" text-color="#9d1d22" v-show="loading"
        size="24px">加载中...</van-loading>
      <div class="qinfo" v-show="loading == false && hasQuestionnaire">
        <!-- <div v-show="loading == false" > -->
        <div v-if="hasQuestionnaire">
          <div class="acontent" v-html="questionnaire.detailsHtml"></div>

          <!-- 问卷问题 -->
          <div v-for="(item, index) in filteredQuestionList" :key="item.id.toString()" class="questionList" :style="item.haserror === true
    ? 'border: 2px solid red;'
    : 'border: 2px solid rgba(255,255,255,0);'
    " v-show="item.isActionTarget != undefined && (!item.isActionTarget || item.isActionShow)"
            :id="item.id.toString()">
            <div class="question">
              <h3>
                <span v-if="item.isMust" class="question-start" style="color: #ff6d56;">*</span>
                {{ index + 1 }}、{{ item.title }}
                {{
    item.typeId == "5" && item.answerText > 0
      ? "(" + item.answerText + "分)"
      : ""
  }}
              </h3>
              <h4>
                {{ item.summary }}

              </h4>
              <van-image v-if="item.imageUrl != undefined && item.imageUrl.length > 10" :src="item.imageUrl">
                <template v-slot:error>图片加载失败</template>
              </van-image>
            </div>
            <div v-if="item.typeId == 1" class="option">
              <van-radio-group v-model="item.checked" @change="checkquestion(index)">
                <van-radio :name="option.id.toString()" v-for="(option, oindex) in item.optionList"
                  :key="option.id.toString()">{{
    GetOptionTitle(option.title, oindex) }}
                  <van-image v-if="option.imageUrl != undefined && option.imageUrl.length > 10
    " :src="option.imageUrl">
                    <template v-slot:error>图片加载失败</template>
                  </van-image>
                </van-radio>
              </van-radio-group>
              <div v-if="HasText || item.hasOther" class="questionInput">
                其他
                <van-field size="large" v-model="item.answerText" :disabled="submitDisabled"
                  :error-message="item.errormessage" @blur="checkInput(index)" />
              </div>
            </div>
            <div v-if="item.typeId == 2" class="option">
              <van-checkbox-group v-model="item.checked" @change="checkquestion(index)">
                <van-checkbox shape="square" :name="option.id.toString()" v-for="(option, oindex) in item.optionList"
                  :key="option.id.toString()">{{ GetOptionTitle(option.title, oindex) }}
                  <van-image v-if="option.imageUrl != undefined && option.imageUrl.length > 10
    " :src="option.imageUrl">
                    <template v-slot:error>图片加载失败</template>
                  </van-image>
                </van-checkbox>
              </van-checkbox-group>
              <div v-if="HasText || item.hasOther" class="questionInput">
                其他
                <van-field size="large" v-model="item.answerText" :disabled="submitDisabled"
                  :error-message="item.errormessage" @blur="checkInput(index)" />
              </div>


            </div>
            <!-- 文本 -->
            <div v-if="item.typeId == 3" class="questionInput">
              <van-field size="large" v-model="item.answerText" :disabled="submitDisabled"
                :error-message="item.errormessage" @blur="checkInput(index)" />
            </div>
            <!-- 整数 -->
            <div v-if="item.typeId == 32" class="questionInput">
              <van-field size="large" type="digit" v-model="item.answerText" :disabled="submitDisabled"
                :maxlength="item.maxLength ? item.maxLength : ''" :error-message="item.errormessage"
                @blur="checkInput(index)" />
            </div>
            <!-- 数字 -->
            <div v-if="item.typeId == 33" class="questionInput">
              <van-field size="large" type="number" v-model="item.answerText" :disabled="submitDisabled"
                :maxlength="item.maxLength ? item.maxLength : ''" :error-message="item.errormessage"
                @blur="checkInput(index)" />
            </div>
            <!-- 手机号 -->
            <div v-if="item.typeId == 31" class="questionInput">
              <van-field size="large" type="tel" maxlength="11" v-model="item.answerText" :disabled="submitDisabled"
                :error-message="item.errormessage" @blur="checkInputPhone(index)" />
            </div>
            <!-- 备注 -->
            <div v-if="item.typeId == 34" class="questionInput">
              <van-field v-model="item.answerText" :disabled="submitDisabled" :error-message="item.errormessage"
                @blur="checkInput(index)" rows="2" autosize type="textarea" />
              <!-- <textarea
              class="texta"
              rows="2"
              autosize
              v-model="item.answerText"
              :disabled="submitDisabled"
              :error-message="item.errormessage"
              @input="checkInput(index)"
            ></textarea> -->
            </div>
            <!-- <div v-if="item.typeId == 32" class="questionInput">
              <van-password-input  :value="item.answerText" :disabled="submitDisabled"
                :length="item.maxLength ? item.maxLength : ''" :error-info="item.errormessage" @blur="checkInput(index)"
                :focused="item.showKeyboard" @focus="item.showKeyboard = true"  :mask="false" />
              <van-number-keyboard v-model="item.answerText" :show="item.showKeyboard" @blur="item.showKeyboard = false" />
            </div> -->


            <!-- 日期 -->
            <div v-if="item.typeId == 4" class="questionInput">
              <van-cell color="#646566" title="日期" :value="item.answerText" @click="item.showcalendar = true" />
              <van-calendar v-model="item.showcalendar" @confirm="onConfirm($event, index)" />
            </div>
            <!-- 评分 -->
            <div v-if="item.typeId == 5" class="option">
              <van-rate v-model="item.answerText" @change="onChange($event, index)" count="10" color="#ffd21e"
                void-icon="star" void-color="#eee" size="25px" />
            </div>
            <!--上传文件-->
            <div v-if="item.typeId == 6">
              <van-field>
                <template #input>
                  <van-uploader v-model="item.filelist" multiple :name="index" :max-count="3"
                    :after-read="afterReadFile" />
                </template>
              </van-field>
            </div>

            <div v-if="item.typeId == 7" class="option">
              <ModalNps :score="item.answerText" @change="onChangeNPS($event, index)" optiondesc1="不可能"
                optiondesc2="极有可能">
              </ModalNps>
            </div>
          </div>


          <div v-if="questionnaire.summary != undefined" style="margin: 0 10px 20px 20px">
            <h3>{{ questionnaire.summary }}</h3>
          </div>
          <div class="questionInput" v-if="HasUserName" :style="usernameerror != ''
    ? 'border: 2px solid red;'
    : 'border: 2px solid rgba(255,255,255,0);'
    ">
            <van-field v-model="answer.userName" size="large" label="收货人" placeholder="请输入收货人" maxlength="15"
              :disabled="submitDisabled" :error-message="usernameerror" @input="inputUserName" />
          </div>
          <div class="questionInput" v-if="HasPhone" :style="phoneerror != ''
    ? 'border: 2px solid red;'
    : 'border: 2px solid rgba(255,255,255,0);'
    ">
            <van-field size="large" type="tel" v-model="answer.phone" :disabled="submitDisabled" label="手机号" 
              maxlength="11" placeholder="请输入手机号" :error-message="phoneerror" @input="inputPhone" />
          </div>

          <div v-if="HasAddress" class="questionInput" :style="addresserror != ''
    ? 'border: 2px solid red;'
    : 'border: 2px solid rgba(255,255,255,0);'
    ">
            <van-field size="large" v-model="answer.address" :disabled="submitDisabled" autosize rows="2" label="收货地址"
              type="textarea" placeholder="请输入收货地址" :error-message="addresserror" @input="inputAddress" />
          </div>

          <div class="submit">
            <van-button v-if="questionnaire.showType == 1 || questionnaire.showType == 500" round block
              :disabled="submitDisabled" color="#a31d0f" native-type="submit" @click="onsubmit()">
              确认提交
            </van-button>
            <div class="submitSuccess" id="posterImage" v-show="questionnaire.showType == 1">
              {{ showMessage }}
            </div>

            <div class="submitError" v-show="questionnaire.showType > 1">
              {{ showMessage }}
            </div>
          </div>

          <div v-if="poster != undefined && poster.length > 0">

            <!-- 轮播图 -->
            <van-swipe class="my-swipe" :autoplay="3000">
              <van-swipe-item v-for="(image, index) in poster" :key="index">
                <img v-lazy="image.qrPrviewImage" />
              </van-swipe-item>
            </van-swipe>
          </div>
        </div>
      </div>

      <div v-if="loading == false && !hasQuestionnaire" style="padding-top: 15px">
        <!-- <div style="color: white; text-align: center">
        {{ showTypeInfo }}
      </div> -->
        <div class="submitError">
          {{ showMessage }}
        </div>
        <div v-if="poster != undefined && poster.length > 0">
          <!-- 轮播图 -->
          <van-swipe class="my-swipe" :autoplay="3000">
            <van-swipe-item v-for="(image, index) in poster" :key="index">
              <img v-lazy="image.qrPrviewImage" />
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";
import ModalNps from '../../components/ModalNps.vue';

import {
  getDevice,
  getQuestionnaire,
  AddAnswer,
  GetActionList,
  uploadfileplus
} from "@/api/question";

export default {
  props: {
    HasAddress: {
      type: Boolean,
      default: false,
    },
    HasText: {
      type: Boolean,
      default: false,
    },
    HasPhone: {
      type: Boolean,
      default: true,
    },
    HasUserName: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      fristPoster: true,
      loading: true,
      questionnaire: {},
      hasQuestionnaire: true,
      phoneerror: "",
      addresserror: "",
      usernameerror: "",
      submitDisabled: false,
      showMessage: "",
      showTypeInfo: "",
      
      answer: {
        questionnaireId: "",
        parentDeviceId: "00000000-0000-0000-0000-000000000000",
        sourceType: 1,
        hasPhone: null,
        deviceId: "",
        userCode: "",
        userName: "",
        phone: "",
        position: "",
        province: "",
        city: "",
        county: "",
        address: "",
        questionList: [],
      },
      poster: [],
      actionList: [],
    };
  },

  created() {
    this.initData();
  },

  computed: {
    filteredQuestionList() {

      var list = [];
      console.log("this.questionnaire.questionList", this.questionnaire.questionList)
      if (this.questionnaire.questionList != undefined) {
        this.questionnaire.questionList.map((item) => {
          if (!item.isActionTarget || item.isActionShow) {
            list.push(item);
            return item;
          }
        });
      }
      return list;
    },
  },

  methods: {
    hidefristPost() {
      this.fristPoster = false;

    },
    async initData() {
      let questionnaireId = "2824f233-0c29-4d32-8b21-88b89aeef41b";
      let userCode = "35009";//赵鑫
      let deviceId = localStorage.getItem("deviceId" + questionnaireId);
      if (deviceId == undefined || deviceId == "" || deviceId == null) {
        var dresult = await getDevice(questionnaireId, userCode);
        localStorage.setItem("deviceId" + questionnaireId, dresult.data.data);
        deviceId = dresult.data.data;
      }

      this.answer.deviceId = deviceId;
      this.answer.questionnaireId = questionnaireId;
      this.answer.userCode = userCode;
      this.answer.hasPhone = this.HasPhone;
      let parentDeviceId = this.$route.query.d;
      if (
        parentDeviceId == undefined ||
        parentDeviceId == "" ||
        parentDeviceId == null
      ) {
        this.answer.parentDeviceId = "00000000-0000-0000-0000-000000000000";
      } else {
        this.answer.parentDeviceId = parentDeviceId;
      }

      var quResult = await getQuestionnaire(questionnaireId, deviceId);

      if (quResult.data.success) {
        var myquestionnaire = quResult.data.data;

        if (myquestionnaire.userTitle && myquestionnaire.userTitle.length) {
          document.title = myquestionnaire.userTitle;
        }


        for (
          let index = 0;
          index < myquestionnaire.questionList.length;
          index++
        ) {
          //初始化附加属性值
          myquestionnaire.questionList[index].haserror = false;
          myquestionnaire.questionList[index].errormessage = "";
          myquestionnaire.questionList[index].filelist = [];
          myquestionnaire.questionList[index].showKeyboard =false;
          
          if (myquestionnaire.questionList[index].typeId == 5 || myquestionnaire.questionList[index].typeId == 7) {
            myquestionnaire.questionList[index].answerText = 0;
          } else {
            myquestionnaire.questionList[index].answerText = "";
          }

          myquestionnaire.questionList[index].showcalendar = false;
          myquestionnaire.questionList[index].isActionSource = false;
          myquestionnaire.questionList[index].isActionTarget = false;
          myquestionnaire.questionList[index].isActionShow = false;
        }
        this.questionnaire = myquestionnaire;
      } else {
        this.hasQuestionnaire = false;
        this.showMessage = quResult.data.errorMessage;
        Toast(quResult.data.errorMessage);
      }

      //获取逻辑信息
      if (this.questionnaire.showType == 1) {
        var action = await GetActionList(questionnaireId)
        if (action.data.success && action.data.data.length > 0) {
          this.actionList = action.data.data;

          for (let index = 0; index < this.questionnaire.questionList.length; index++) {
            for (let a = 0; a < this.actionList.length; a++) {
              if (this.questionnaire.questionList[index].id == this.actionList[a].questionId) {
                this.questionnaire.questionList[index].isActionSource = true;
              }
              if (this.questionnaire.questionList[index].id == this.actionList[a].actionQuestionId) {
                this.questionnaire.questionList[index].isActionTarget = true;
              }
            }
          }
        }

      }

      if (this.questionnaire.showType > 1) {
        this.submitDisabled = true;
        this.hasQuestionnaire = false;
      }
      if (this.questionnaire.showType == 2) {
        this.showMessage = this.questionnaire.title + "未开始，敬请期待！";
      }
      if (this.questionnaire.showType == 3) {
        this.showMessage =
          this.questionnaire.title + "已结束，期待您下次参与!";
      }
      if (this.questionnaire.showType == 4) {
        this.showMessage = this.questionnaire.successMessage ? this.questionnaire.successMessage : "您已提交成功，感谢您的帮助与支持!";
        this.showTypeInfo = this.questionnaire.showTypeInfo;
      }
      if (this.questionnaire.showType == 5) {
        this.showMessage = this.questionnaire.successMessage ? this.questionnaire.successMessage : "您已提交成功，感谢您的帮助与支持!!";
        this.showTypeInfo = this.questionnaire.showTypeInfo;
      }
      if (this.questionnaire.showType == 6) {
        this.showMessage = this.questionnaire.successMessage ? this.questionnaire.successMessage : "您已提交成功，感谢您的帮助与支持!!!";
        this.showTypeInfo = this.questionnaire.showTypeInfo;
      }
      if (
        this.questionnaire.showType >= 4 &&
        this.questionnaire.showType <= 6
      ) {
        await this.getMyPoster();
      }

      this.loading = false;
    },

    afterReadFile: async function (files, detail) {
      Toast("文件上传中");
      let formData = new FormData();
      formData.append("file", files.file);
      let aresult = await uploadfileplus(formData, "QA");

      let url = aresult.data; // Get url from response
      let index = detail.name;
      this.questionnaire.questionList[index].filelist =
        this.questionnaire.questionList[index].filelist.filter(function (item) {
          return item.url != undefined && item.url.indexOf("http") >= 0
        });
      this.questionnaire.questionList[index].filelist.push({
        url: url,
        imageFit: "contain",
        previewSize: 80,
      });

      Toast("文件上传成功");
    },
    onChange(value, index) {
      Toast("评分：" + value + "分");
      this.filteredQuestionList[index].haserror = false;
      this.filteredQuestionList[index].answerText = value;
      this.handlerScoreChange(value, index);
    },
    onChangeNPS(value, index) {
      Toast(value);
      this.filteredQuestionList[index].haserror = false;
      this.filteredQuestionList[index].answerText = value;
      this.handlerScoreChange(value, index);
    },
    handlerScoreChange(value, index) {
      if (this.filteredQuestionList[index].isActionSource) {
        //
        for (let a = 0; a < this.actionList.length; a++) {
          //找到 触发逻辑的问题 this.actionList[a]
          if (this.filteredQuestionList[index].id == this.actionList[a].questionId) {
            for (let q = 0; q < this.questionnaire.questionList.length; q++) {
              //找到 逻辑问题  this.questionnaire.questionList[q]
              if (this.questionnaire.questionList[q].id == this.actionList[a].actionQuestionId) {
                if (this.actionList[a].selectNumMin <= value && value <= this.actionList[a].selectNumMax) {
                  this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? true : false;
                }
                else {
                  this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? false : true;
                }

                //清空选项
                if (this.questionnaire.questionList[q].isActionShow == false) {
                  this.clearHasCheck(q);
                }
                else {
                  this.showCheck(q);
                }
                break;
              }
            }
          }


        }
      }
    },
    checkquestion(index) {
      console.log("check", index);
      this.filteredQuestionList[index].haserror = false;
      if (this.filteredQuestionList[index].typeId == 2 && this.filteredQuestionList[index].checked) {
        if (this.filteredQuestionList[index].maxLength > 0 && this.filteredQuestionList[index].checked.length > this.filteredQuestionList[index].maxLength) {
          this.filteredQuestionList[index].haserror = true;
          Toast((index + 1) + "、" + this.filteredQuestionList[index].title + "最多选" + this.filteredQuestionList[index].maxLength + "项");
        }
        if (this.filteredQuestionList[index].minLength > 0 && this.filteredQuestionList[index].checked.length < this.filteredQuestionList[index].minLength) {
          this.filteredQuestionList[index].haserror = true;
          Toast((index + 1) + "、" + this.filteredQuestionList[index].title + "最少选" + this.filteredQuestionList[index].minLength + "项");
        }
      }
      if (this.filteredQuestionList[index].isActionSource) {
        //
        for (let a = 0; a < this.actionList.length; a++) {
          //找到 触发逻辑的问题 this.actionList[a]
          if (this.filteredQuestionList[index].id == this.actionList[a].questionId) {

            for (let q = 0; q < this.questionnaire.questionList.length; q++) {
              //找到 逻辑问题  this.questionnaire.questionList[q]
              if (this.questionnaire.questionList[q].id == this.actionList[a].actionQuestionId) {
                //选中任意一项
                if (this.actionList[a].selectOption == "00000000-0000-0000-0000-000000000000") {
                  if (this.filteredQuestionList[index].checked.length >= 0) //选中
                  {
                    this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? true : false;
                  }
                  else {
                    this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? false : true;
                  }
                  break;
                }

                if (this.actionList[a].selectType == 1)// 选中逻辑
                {
                  this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? true : false;
                  if (this.filteredQuestionList[index].checked.indexOf(this.actionList[a].selectOption) >= 0) //选中
                  {
                    this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? true : false;
                  }
                  else {
                    this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? false : true;
                  }
                }
                if (this.actionList[a].selectType == 2)// 未选中
                {
                  this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? true : false;
                  if (this.filteredQuestionList[index].checked.indexOf(this.actionList[a].selectOption) == -1) //未选中
                  {
                    this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? true : false;
                  }
                  else {
                    this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? false : true;
                  }



                }

                //清空选项
                if (this.questionnaire.questionList[q].isActionShow == false) {
                  this.clearHasCheck(q);
                }
                else {
                  this.showCheck(q);
                }

                break;
              }
            }

          }
        }
      }


    },

    clearHasCheck(index) {

      // console.log("clear", this.questionnaire.questionList[index].title);

      // if (this.questionnaire.questionList[index].typeId == 2) {
      //   this.questionnaire.questionList[index].checked = [];
      // }
      // else if (this.questionnaire.questionList[index].typeId == 1) {
      //   console.log("");
      //   //delete this.questionnaire.questionList[index].checked;
      //   //this.questionnaire.questionList[index].checked=false;
      // }
      // else if (this.questionnaire.questionList[index].typeId == 7) {
      //   this.questionnaire.questionList[index].answerText = 0
      // }
      if (this.questionnaire.questionList[index].isActionSource) {
        for (let a = 0; a < this.actionList.length; a++) {
          //找到 触发逻辑的问题 this.actionList[a]
          if (this.questionnaire.questionList[index].id == this.actionList[a].questionId) {

            for (let q = 0; q < this.questionnaire.questionList.length; q++) {
              //找到 逻辑问题  this.questionnaire.questionList[q]
              if (this.questionnaire.questionList[q].id == this.actionList[a].actionQuestionId) {
                // //选中任意一项
                // if (this.actionList[a].selectOption == "00000000-0000-0000-0000-000000000000") {
                //   if (this.questionnaire.questionList[index].checked?.length >= 0) //选中
                //   {
                //     this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? true : false;
                //   }
                //   else {
                //     this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? false : true;
                //   }
                //   break;
                // }

                // if (this.actionList[a].selectType == 1)// 选中逻辑
                // {
                //   this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? true : false;
                //   if (this.questionnaire.questionList[index].checked?.indexOf(this.actionList[a].selectOption) >= 0) //选中
                //   {
                //     this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? true : false;
                //   }
                //   else {
                //     this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? false : true;
                //   }
                // }
                // if (this.actionList[a].selectType == 2)// 未选中
                // {
                //   this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? true : false;
                //   if (this.questionnaire.questionList[index].checked?.indexOf(this.actionList[a].selectOption) == -1) //未选中
                //   {
                //     this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? true : false;
                //   }
                //   else {
                //     this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? false : true;
                //   }
                // }

                this.questionnaire.questionList[q].isActionShow = false;

                //清空选项
                if (this.questionnaire.questionList[q].isActionShow == false) {
                  this.clearHasCheck(q);
                }

                break;
              }
            }

          }
        }

      }

    },

    showCheck(index) {
      if (this.questionnaire.questionList[index].isActionSource) {
        for (let a = 0; a < this.actionList.length; a++) {
          //找到 触发逻辑的问题 this.actionList[a]
          if (this.questionnaire.questionList[index].id == this.actionList[a].questionId) {

            for (let q = 0; q < this.questionnaire.questionList.length; q++) {
              //找到 逻辑问题  this.questionnaire.questionList[q]
              if (this.questionnaire.questionList[q].id == this.actionList[a].actionQuestionId) {
                //选中任意一项
                if (this.actionList[a].selectOption == "00000000-0000-0000-0000-000000000000") {
                  if (this.questionnaire.questionList[index].checked?.length >= 0) //选中
                  {
                    this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? true : false;
                  }
                  else {
                    this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? false : true;
                  }
                  break;
                }

                if (this.actionList[a].selectType == 1)// 选中逻辑
                {
                  this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? true : false;
                  if (this.questionnaire.questionList[index].checked?.indexOf(this.actionList[a].selectOption) >= 0) //选中
                  {
                    this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? true : false;
                  }
                  else {
                    this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? false : true;
                  }
                }
                if (this.actionList[a].selectType == 2)// 未选中
                {
                  this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? true : false;
                  if (this.questionnaire.questionList[index].checked?.indexOf(this.actionList[a].selectOption) == -1) //未选中
                  {
                    this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? true : false;
                  }
                  else {
                    this.questionnaire.questionList[q].isActionShow = this.actionList[a].actionType == 1 ? false : true;
                  }
                }

                // this.questionnaire.questionList[q].isActionShow=false;

                //清空选项
                if (this.questionnaire.questionList[q].isActionShow == true) {
                  this.showCheck(q);
                }
                if (this.questionnaire.questionList[q].isActionShow == false) {
                  this.clearHasCheck(q);
                }

                break;
              }
            }

          }
        }

      }
    },

    checkInput(index) {
      if (
        (this.filteredQuestionList[index].typeId == 3 ||
          (this.filteredQuestionList[index].typeId >= 31 &&
            this.filteredQuestionList[index].typeId <= 39)) &&
        this.filteredQuestionList[index].isMust
      ) {
        if (
          (this.filteredQuestionList[index].minLength ||
            this.filteredQuestionList[index].minLength > 0) &&
          this.filteredQuestionList[index].answerText.replace(
            /(^\s*)|(\s*$)/g,
            ""
          ).length < this.filteredQuestionList[index].minLength
        ) {
          this.filteredQuestionList[index].errormessage =
            "最小长度是" +
            this.filteredQuestionList[index].minLength +
            "个字符";
          this.filteredQuestionList[index].haserror = true;
        }
        else if (this.filteredQuestionList[index].answerText == "" && this.filteredQuestionList[index].isMust) {
          this.filteredQuestionList[index].haserror = true;
          this.filteredQuestionList[index].errormessage = "不可为空";
        }
        else {
          this.filteredQuestionList[index].haserror = false;
          this.filteredQuestionList[index].errormessage = "";
        }
      }
    },
    checkInputPhone(index) {
      if (this.filteredQuestionList[index].answerText.length < 11) {
        this.filteredQuestionList[index].errormessage = "";
        return;
      }
      var phonereg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (!phonereg.test(this.filteredQuestionList[index].answerText)) {
        this.filteredQuestionList[index].errormessage =
          "请填写有效的手机号码";
        this.filteredQuestionList[index].haserror = true;
      } else {
        this.filteredQuestionList[index].errormessage = "";
        this.filteredQuestionList[index].haserror = false;
      }
    },
    async onsubmit() {
      this.submitDisabled = true;
      // var checked = true;
      // var checkeditem;
      var itemerror = "";
      for (
        let index = 0;
        index < this.filteredQuestionList.length;
        index++
      ) {
        // element是所有信息
        const element = this.filteredQuestionList[index];
        if (element.typeId == 1
          && element.isMust
          && element.checked == undefined
          && element.answerText == ""
        ) {
          element.haserror = true;
        } else if (
          element.typeId == 2 && element.isMust
          && (element.checked == undefined || element.checked.length == 0)
          && element.answerText == ""
        ) {
          element.haserror = true;
        } else if (
          (element.typeId == 4 ||
            element.typeId == 3 ||
            (element.typeId >= 31 && element.typeId <= 39)) &&
          element.isMust &&
          element.answerText == ""
        ) {
          element.haserror = true;
          this.filteredQuestionList[index].errormessage == "不可为空"
        }
        else if ((element.typeId == 5 || element.typeId == 7) && element.answerText == 0) {
          element.haserror = true;
        }

        if (element.typeId == 31 && element.isMust) {
          if (!phonereg.test(element.answerText)) {
            element.errormessage = "请填写有效的手机号码";
            element.haserror = true;
          } else {
            element.haserror = false;
            element.errormessage = "";
          }
        }

        // str.replace(/(^\s*)|(\s*$)/g, "")
        if (
          (element.typeId == 3 ||
            (element.typeId >= 31 && element.typeId <= 39)) &&
          element.isMust
        ) {
          if (
            (element.minLength || element.minLength > 0) &&
            element.answerText.replace(/(^\s*)|(\s*$)/g, "").length <
            element.minLength
          ) {
            element.errormessage = "最小长度是" + element.minLength + "个字符";
            element.haserror = true;
          }
        }

        if (
          element.typeId == 6 &&

          element.isMust
        ) {

          if (element.filelist.length == 0) {
            element.errormessage = "请上传文件";
            element.haserror = true;
          }
        }






      }

      for (
        let index = 0;
        index < this.filteredQuestionList.length;
        index++
      ) {
        // element是所有信息
        const element = this.filteredQuestionList[index];


        if (this.filteredQuestionList[index].typeId == 2 && this.filteredQuestionList[index].checked) {
          if (this.filteredQuestionList[index].maxLength > 0 && this.filteredQuestionList[index].checked.length > this.filteredQuestionList[index].maxLength) {
            this.filteredQuestionList[index].haserror = true;
            Toast((index + 1) + "、" + this.filteredQuestionList[index].title + "最多选" + this.filteredQuestionList[index].maxLength + "项");
            document.getElementById(element.id).scrollIntoView();
            this.submitDisabled = false;
            return;
          }
          if (this.filteredQuestionList[index].minLength > 0 && this.filteredQuestionList[index].checked.length < this.filteredQuestionList[index].minLength) {
            this.filteredQuestionList[index].haserror = true;
            Toast((index + 1) + "、" + this.filteredQuestionList[index].title + "最少选" + this.filteredQuestionList[index].minLength + "项");
            document.getElementById(element.id).scrollIntoView();
            this.submitDisabled = false;
            return;
          }
        }



        //第一个错误,页面会滚动到
        if (element.haserror) {
          itemerror =
            index +
            1 +
            "、" +
            element.title +
            (element.typeId <= 2 ? " 请选择" : " 请填写");
          //checked = false;
          Toast(itemerror);
          document.getElementById(element.id).scrollIntoView();
          this.submitDisabled = false;
          return;
        }
      }

      if (this.HasPhone) {
        var phonereg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if (!phonereg.test(this.answer.phone)) {
          this.phoneerror = "请填写有效的手机号码";
        } else {
          this.phoneerror = "";
        }
      }

      if (this.HasUserName) {
        if (this.answer.userName == "") {
          this.usernameerror = "请填写收货人";
        } else {
          this.usernameerror = "";
        }
      }
      if (this.HasAddress) {
        if (this.answer.address == "") {
          this.addresserror = "请填写收货地址";
        } else {
          this.addresserror = "";
        }
      }

      // if (!checked && checkeditem !== undefined) {
      //   Toast(itemerror);
      //   document.getElementById(checkeditem.id).scrollIntoView();
      //   this.submitDisabled = false;
      //   return;
      // }
      if (
        this.phoneerror != "" ||
        this.usernameerror != "" ||
        this.addresserror != ""
      ) {
        this.submitDisabled = false;
        return;
      }
      //问卷答案
      this.answer.questionList = [];
      for (
        let index = 0;
        index < this.filteredQuestionList.length;
        index++
      ) {
        const question = this.filteredQuestionList[index];
        var option = {};
        if (question.typeId == 1) {
          option = {
            id: question.id,
            typeId: question.typeId,
            optionValue: question.checked?.toString(),
            answerText: this.HasText || (question.minLength &&
              question.minLength > 0) ? question.answerText.toString() : "",
          };
        }
        else if (question.typeId == 6) {

          let urls = "";
          question.filelist.forEach(element => {
            if (element.url != undefined) {
              urls += element.url + " "
            }
          });
          urls = urls.trimEnd();
          option = {
            id: question.id,
            typeId: question.typeId,
            optionValue: "",
            answerText: urls,
          };
        }

        else if (question.typeId == 2) {
          option = {
            id: question.id,
            typeId: question.typeId,
            optionValue: question.checked?.toString(),
            answerText: this.HasText || (question.minLength &&
              question.minLength > 0) ? question.answerText.toString() : "",
          };
        } else if (question.typeId >= 3 && question.typeId <= 50) {
          option = {
            id: question.id,
            typeId: question.typeId,
            optionValue: "",
            answerText: question.answerText.toString(),
          };
        }
        this.answer.questionList.push(option);
      }
      // var amapInfo = localStorage.getItem("AMapInfo");
      // if (amapInfo == "SUCCESS") {
      //   this.answer.province = localStorage.getItem("AMapInfo_province");
      //   this.answer.city = localStorage.getItem("AMapInfo_city");
      //   this.answer.county = localStorage.getItem("AMapInfo_district");
      //   this.answer.address = localStorage.getItem("AMapInfo_address");
      //   this.answer.position =
      //     localStorage.getItem("AMapInfo_lng") +
      //     "," +
      //     localStorage.getItem("AMapInfo_lat");
      //   if (this.answer.city == "") {
      //     this.answer.city = this.answer.province;
      //   }
      // }

      try {
        var areulst = await AddAnswer(this.answer);

        if (areulst.data.success) {
          Dialog.alert({
            title: '提交成功',
            message: this.questionnaire.successMessage ? this.questionnaire.successMessage : "提交成功，感谢您的帮助与支持!!!",
          }).then(() => {
            // on close
          });
          this.showMessage = this.questionnaire.successMessage ? this.questionnaire.successMessage : "提交成功，感谢您的帮助与支持!!!"
          await this.getMyPoster();
        }

        else {
          Toast(areulst.data.errorMessage);
          this.submitDisabled = false; //questionnaire.showType
          this.questionnaire.showType = 500;
          this.showMessage = areulst.data.errorMessage;
        }
      } catch (error) {
        this.submitDisabled = false;
      }
    },

    async getMyPoster() {

      this.poster = [{ qrPrviewImage: './image/njxg/success3.jpg' }];
      if (this.hasQuestionnaire && this.poster.length > 0) {
        setTimeout(() => {
          document.getElementById("posterImage").scrollIntoView();
        }, 500);
      }
    },
    inputPhone() {
      if (this.answer.phone.length < 11) {
        this.phoneerror = "";
        return;
      }
      var phonereg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!phonereg.test(this.answer.phone)) {
        this.phoneerror = "请填写有效的手机号码";
      } else {
        this.phoneerror = "";
      }
    },
    inputUserName() {
      if (this.answer.userName.length < 1) {
        return;
      }
      if (this.answer.userName == "") {
        this.usernameerror = "请填写收货人";
      } else {
        this.usernameerror = "";
      }
    },
    inputAddress() {
      if (this.answer.address != undefined && this.answer.address.length < 1) {
        return;
      }
      if (this.answer.address == "") {
        this.addresserror = "请填写收货地址";
      } else {
        this.addresserror = "";
      }
    },

    GetOptionTitle(title, index) {
      var optionIndex = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      return optionIndex[index] + "、" + title;
    },
  },
  components: {
    ModalNps
  },
};
</script>

<style lang="less" scoped>
.loading {
  text-align: center;
}

.van-cell {
  background-color: unset;
}

.container {
  margin: 0 auto;
  max-width: 750px;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;

  //padding: 0px 10px 20px 10px;
  /deep/.van-field__control,
  input,
  textarea {
    border: 1px solid #d8d8d8; 
    background-color: #f3f4f8;
  }
  /deep/.van-field__control:focus,
  input:focus,
  textarea:focus {
    border: 1px solid #2672ff; 
    
  }
//   input:focus {
//   border: 1px solid blue;
//   outline: none; /* 可选，去除默认的边框样式 */
// }
}

.qinfo {
  padding-bottom: 50px;
}

.questionList {
  margin: 0px 10px 20px 10px;
}

.questionInput {
  // height: 75px;
  margin: 0px 10px 5px 10px;
  font-size: 16px;

}

.question {
  margin: 20px 10px 20px 10px;
}

.van-checkbox {
  margin: 10px 0px 10px 5px;
  border: 1px solid #d8d8d8;
  padding: 5px;
}

.van-checkbox[aria-checked="true"] {
  border: 1px solid #2672ff;
  padding: 5px;
}

.van-radio {
  margin: 10px 0px 10px 5px;
  border: 1px solid #d8d8d8;
  padding: 5px;
}

.van-radio[aria-checked="true"] {
  border: 1px solid #2672ff;
  padding: 5px;
}

.submit {
  padding: 30px 20px 10px;

  /deep/ .van-button--normal {
    padding: 0 15px;
    font-size: 18px;
  }
}

.submitInfo {
  padding-top: 10px;
  font-size: 18px;
  text-align: center;
}

.submitSuccess {
  color: green;
  padding-top: 10px;
  font-size: 18px;
  text-align: center;
}

.submitError {
  color: red;
  padding-top: 10px;
  font-size: 18px;
  text-align: center;
}

.posterInfo {
  padding-top: 10px;
  font-size: 15px;
  text-align: center;
}

.van-field__label {
  color: #2672ff
}

.acontent {
  /deep/ img {
    max-width: 100% !important;
    display: block;
    border: 0;
  }

  /deep/ video {
    max-width: 100% !important;
  }

  /deep/ p {
    text-indent: 35px
  }
}

.my-swipe .van-swipe-item {
  //height: 150px;
  color: #fff;
  font-size: 20px;
  text-align: center;
  //background-color: #39a9ed;
}

.my-swipe img {
  display: inline-block;
  width: 100%;
}
</style>